import $ from "jquery";
window.$ = window.jquery = $;

import React, { useEffect, useState } from "react";
// import "../../../../public/js/fslightbox";
import fslightbox from "fslightbox";
// import OwlCarousel from "react-owl-carousel";
import loadable from "@loadable/component";
import FsLightbox from "fslightbox-react";
import { useFsLightboxContext } from "../../../context/FsLightboxContext";
import { room_1, room_2 } from "../../../assets/img";

const OwlCarousel = loadable(() => import("react-owl-carousel"));

const GallerySection = () => {
  // const [lightBoxToggler, setLightBoxToggler] = useState(false);
  const { fsToggler, setFsToggler } = useFsLightboxContext();
  const carouselOptions = {
    loop: true,
    margin: 5,
    nav: true,
    dots: false,
    center: true,
    navText: [
      "<i class='bi bi-arrow-left-short'></i>",
      "<i class='bi bi-arrow-right-short'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  useEffect(() => {
    // require("fslightbox");
  }, []);

  return (
    <div className="container margin_120" id="gallery">
      <div className="row">
        <div className="title col-12 text-center mb-5">
          <small data-cue="slideInUp">Luxury B&B Experience</small>
          <h2 data-cue="slideInUp" data-delay="100">
            Interior Gallery
          </h2>
        </div>
      </div>
      <div data-cues="zoomIn">
        <OwlCarousel
          {...carouselOptions}
          className="owl-carousel owl-theme carousel_item_centered kenburns rounded-img"
        >
          {Array(3)
            .fill()
            .map((item, index) => {
              return (
                <div key={index} className="item">
                  <img src={room_2} alt="" />
                </div>
              );
            })}
        </OwlCarousel>
      </div>
      <div className="text-center mt-5">
        {/* <a
          // data-fslightbox="gallery_1"
          // data-type="image"
          // href="img/gallery/1.jpg"
          className="btn_1 outline"
        >
          FullScreen Gallery
        </a> */}
        <button
          className="btn_1 outline"
          onClick={() => setFsToggler(!fsToggler)}
        >
          Fullscreen Gallery
        </button>
      </div>
    </div>
  );
};

export default GallerySection;
