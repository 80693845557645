import $ from "jquery";
window.$ = window.jquery = $;

import React, { useEffect, useState } from "react";
// import "../../../../public/js/fslightbox";
import fslightbox from "fslightbox";
// import OwlCarousel from "react-owl-carousel";
import loadable from "@loadable/component";
import FsLightbox from "fslightbox-react";
import { useFsLightboxContext } from "../../context/FsLightboxContext";
import { room_1, room_2, about_1, about_2 } from "../../assets/img";
import Base from '../utils/base'
import { useTranslation } from "react-i18next";

const OwlCarousel = loadable(() => import("react-owl-carousel"));

const Gallery = () => {
	var base = new Base()
  // const [lightBoxToggler, setLightBoxToggler] = useState(false);
  const { fsToggler, setFsToggler, setFsData } = useFsLightboxContext();
	const {t, i18n} = useTranslation()
  const carouselOptions = {
    loop: true,
    margin: 5,
    nav: true,
    dots: false,
    center: true,
    autoHeight: true,
    navText: [
      "<i class='bi bi-arrow-left-short'></i>",
      "<i class='bi bi-arrow-right-short'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  // const galleryData = [room_1, about_1, room_2, about_2];
	const [arrGallery, setArrGallery] = useState([]);
	const [arrGalleryImage, setArrGalleryImage] = useState([]);
	const [galleryData, setGalleryData] = useState({});
	
	useEffect(() => {
		get_data()
		get_info()
	}, [])

  useEffect(() => {
    // require("fslightbox");
		if(arrGallery.length > 0){
    	scrollCue.init({
      	percentage: 0.85,
    	});
    	scrollCue.update();
		}
    return () => {
			if(scrollCue != null)
      	scrollCue.enable(false);
    };
  }, [arrGallery,]);
	
	async function get_info() {
		var response = await base.request(base.url_api + "/info?type=gallery")
	
		if(response != null){
			if(response.status == "success"){
				setGalleryData(response.data)
			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}
	
	async function get_data() {
		var response = await base.request(base.url_api + "/gallery")
	
		if(response != null){
			if(response.status == "success"){
				var arr = []
				for(let data of response.data)
					arr.push(data.url_image)
				setArrGallery(response.data)
				setArrGalleryImage(arr)
			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}

  return (
    <div className="container margin_120" id="gallery">
      <div className="row">
        <div className="title col-12 text-center mb-5">
          <small data-cue="slideInUp">{galleryData.subtitle}</small>
          <h2 data-cue="slideInUp" data-delay="100">
            {galleryData.title}
          </h2>
        </div>
      </div>
      <OwlCarousel
        {...carouselOptions}
        className="owl-carousel owl-theme carousel_item_centered kenburns rounded-img"
      >
        {arrGallery.map((item, index) => (
          <div
            key={index}
            className="item d-flex overflow-hidden align-items-center"
            style={{ aspectRatio: 650 / 400 }}
          >
            <img src={item.url_image} alt="" />
          </div>
        ))}
      </OwlCarousel>
      <div className="text-center mt-5">
        {/* <a
          // data-fslightbox="gallery_1"
          // data-type="image"
          // href="img/gallery/1.jpg"
          className="btn_1 outline"
        >
          FullScreen Gallery
        </a> */}
        <button
          className="btn_1 outline"
          onClick={() => setFsToggler(!fsToggler) || setFsData(arrGalleryImage)}
        >
					{t('gallery.fullscreenGallery')}
        </button>
      </div>
    </div>
  );
};

export default Gallery;
