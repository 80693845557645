import React, {useEffect, useState,} from "react";
import Base from '../../utils/base'

const MainFacilitiesSection = () => {
	var base = new Base()
	const [facility, setFacility] = useState({});
	const [arrFacility, setArrFacility] = useState([]);
	
	useEffect(() => {
		get_info()
		get_facility()
	}, [])
	
	async function get_info() {
		var response = await base.request(base.url_api + "/info?type=facility")
	
		if(response != null){
			if(response.status == "success"){
				setFacility(response.data)
			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}
	
	async function get_facility() {
		var response = await base.request(base.url_api + "/facility/all")
	
		if(response != null){
			if(response.status == "success"){
				setArrFacility(response.data)
			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}
	
  return (
    <div className="bg_white">
      <div className="container margin_120_95">
        <div className="title text-center mb-5">
          <div data-cue="slideInUp">
            <small>{facility.subtitle}</small>
          </div>
          <div data-cue="slideInUp" data-delay="100">
            <h2>{facility.title}</h2>
          </div>
        </div>
        <div className="row mt-4" data-cue="slideInUp">
				
					{
						arrFacility.map((facility_data, index) => (
							<div className="col-xl-4 col-md-6" key={index}>
								<div className="box_facilities no-border" >
									<img src={facility_data.url_image} style={{width: '65px', height: '65px',}}/>
									<h3 className="mt-3">{facility_data.title}</h3>
									<p>{facility_data.subtitle}</p>
								</div>
							</div>
						))
					}
					
        </div>
      </div>
    </div>
  );
};

export default MainFacilitiesSection;
