import $ from "jquery";
window.$ = window.jquery = $;

import React, { useEffect } from "react";
// import "theia-sticky-sidebar";
import {
	theomalaLogoHorizontalLarge,
	theomalaLogoHorizontalNavbar,
	vr_tour,
} from "../../assets/img";
import { Link, NavLink, useHref, useParams } from "react-router-dom";
import Rooms from "../rooms";
import { useTranslation } from "react-i18next";


const Navbar = () => {
	const href = useHref();
	if (href != "/") {
		$(".fixed_header").addClass("sticky");
	} else {
		$(".fixed_header").removeClass("sticky");
	}

  const {t, i18n} = useTranslation()

	$(window).on("scroll", function () {
		if (href == "/") {
			if ($(this).scrollTop() > 1) {
				$(".fixed_header").addClass("sticky");
			} else {
				$(".fixed_header").removeClass("sticky");
			}
		} else {
			$(".fixed_header").addClass("sticky");
		}
	});

	const handleSmoothScroll = () => {
		const handleSmoothScroll = (e) => {
			const link = e.target.closest('a.js-scroll-trigger[href*="#"]:not([href="#"])');
			if (!link) return;

			e.preventDefault();

			if (
				location.pathname.replace(/^\//, "") === link.pathname.replace(/^\//, "") &&
				location.hostname === link.hostname
			) {
				const target =
					document.querySelector(link.hash) ||
					document.querySelector(`[name=${link.hash.slice(1)}]`);

				if (target) {
					const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - 50;
					window.scrollTo({
						top: targetPosition,
						behavior: "smooth",
					});
				}
			}
		};

		console.log(href);

		document.addEventListener("click", handleSmoothScroll);

		// Cleanup function to remove the event listener
		return () => {
			document.removeEventListener("click", handleSmoothScroll);
		};
	};

	const menuOnClick = () => {
		$(".main-menu").toggleClass("show");
		$(".layer").toggleClass("layer-is-visible");
		// console.log("click");
	};

  const changeLanguage = async(lang) =>{
    i18n.changeLanguage(lang);
    await window.localStorage.setItem('lang', lang)
    location.reload()
  }

	useEffect(() => {
		$(".fixed_title").theiaStickySidebar({
			minWidth: 991,
			additionalMarginTop: 120,
		});

		$('a[href^="#"].btn_scrollto').on("click", function (e) {
			e.preventDefault();
			var target = this.hash;
			var $target = $(target);
			$("html, body")
				.stop()
				.animate(
					{
						scrollTop: $target.offset().top - 60,
					},
					300,
					"swing",
					function () {
						window.location.hash = target;
					}
				);
		});

		handleSmoothScroll();

		// Close responsive menu when a scroll trigger link is clicked
		$(".js-scroll-trigger").click(function () {
			$(".nav_panel").toggleClass("show");
			$(".main-menu").toggleClass("show");
			$(".layer").toggleClass("layer-is-visible");
		});
	}, []);

	return (
		<header className={"fixed_header menu_v1"}>
			<div className="layer"></div>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-3 col-5">
						<Link href={"/"} className="logo_normal">
							<img
								src={theomalaLogoHorizontalLarge}
								width="100"
								height="34"
								alt=""
								className="img-fluid"
								style={{ filter: "brightness(100)" }}
							/>
						</Link>
						<Link to={"/"} className="logo_sticky">
							<img
								src={theomalaLogoHorizontalLarge}
								width="100"
								height="34"
								alt=""
								className="img-fluid"
							/>
						</Link>
					</div>
					<div className="col-md-9 col-7">
						<div className="main-menu">
							<a href="#0" className="closebt open_close_menu" onClick={menuOnClick}>
								<i className="bi bi-x"></i>
							</a>
							<div className="logo_panel">
								<img src={theomalaLogoHorizontalLarge} width={180} alt="" />
							</div>
							<nav id="mainNav">
								<ul>
									{/* <li>
										<a
											href="#about"
											className="js-scroll-trigger animated_link"
											onClick={menuOnClick}>
											About
										</a>
									</li> */}
									<li>
										<Link to={"#"} className={"js-scroll-trigger "}>
											<img src={vr_tour} width={55} height={55} />
										</Link>
									</li>
									<li>
										<Link to={"/"} className={"js-scroll-trigger animated_link"}>
                      {t('navbar.home')}
										</Link>
									</li>
									<li>
										<Link to={"/rooms"} className={"js-scroll-trigger animated_link"}>
                      {t('navbar.rooms')}
										</Link>
										{/* <a
                      href="#rooms"
                      className="js-scroll-trigger animated_link"
                      onClick={menuOnClick}
                    >
                      Rooms
                    </a> */}
									</li>
									<li>
										<Link to={"/gallery"} className={"js-scroll-trigger animated_link"}>
                      {t('navbar.gallery')}
										</Link>
										{/* <a
                      href="#gallery"
                      className="js-scroll-trigger animated_link"
                      onClick={menuOnClick}
                    >
                      Gallery
                    </a> */}
									</li>
									{/* <li>
										<a
											href="#amenities"
											className="js-scroll-trigger animated_link"
											onClick={menuOnClick}>
											Activities
										</a>
									</li>
									<li>
										<a
											href="#contacts"
											className="js-scroll-trigger animated_link"
											onClick={menuOnClick}>
											Contacts
										</a>
									</li>
									<li>
										<a
											href="demos.html"
											className="js-scroll-trigger animated_link"
											onClick={menuOnClick}>
											Demos
										</a>
									</li> */}
									<li>
										<a href="#booking_section" className="js-scroll-trigger btn_1">
                      {t('navbar.bookNow')}
										</a>
									</li>
									<li>
										<div className="dropdown">
											{/* <button
												className="js-scroll-trigger animated_link dropdown-toggle"
												type="button"
												data-bs-toggle="dropdown"
												aria-expanded="false">
												Dropdown button
                        EN
											</button> */}
                      <a href="#!" className="js-scroll-trigger animated_link" type="button"
												data-bs-toggle="dropdown"
												aria-expanded="false">
                        {localStorage.getItem('lang') != null ? localStorage.getItem('lang').toUpperCase() : 'en'}
                      </a>
											<ul className="dropdown-menu dropdown-menu-end" style={{minWidth: "unset"}}>
												{/* <li>
													<a className="dropdown-item active" href="#">
														Action
													</a>
												</li> */}
												<li className="m-0">
													<a className="dropdown-item text-black d-flex" href="#" onClick={() => changeLanguage("en")}>
														EN
													</a>
												</li>
												<li className="m-0">
													<a className="dropdown-item d-flex text-black" href="#" onClick={() => changeLanguage("id")}>
														ID
													</a>
												</li>
												{/* <li>
													<hr className="dropdown-divider" />
												</li> */}
												{/* <li>
													<a className="dropdown-item" href="#">
														Separated link
													</a>
												</li> */}
											</ul>
										</div>
										{/* <a href="#" className="js-scroll-trigger ">
											EN
										</a> */}
									</li>
								</ul>
							</nav>
						</div>
						<div className="hamburger_2 open_close_menu float-end" onClick={menuOnClick}>
							<div className="hamburger__box">
								<div className="hamburger__inner"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Navbar;
