import $ from "jquery";
window.$ = window.jquery = $;
import "../../../../public/js/vegas";

import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import { fotoSampulA, room_1, room_2 } from "../../../assets/img";
import Base from '../../utils/base'

// import '../../../../public/js/slider'
const OwlCarousel = loadable(() => import("react-owl-carousel"));

const HeroSlider = () => {
	var base = new Base()
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	const [slides, setSlides] = useState([]);

	useEffect(() => {

		$(".opacity-mask").each(function () {
			$(this).css("background-color", $(this).attr("data-opacity-mask"));
		});

		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);

		$(".owl-slide-animated").removeClass("is-transitioned")
		console.log("width", windowSize)
		get_data()

		// return () => {
		//   window.removeEventListener("resize", handleResize);
		// };
	}, []);

	const carouselOptions = {
		items: 1,
		loop: true,
		nav: false,
		dots: true,
		mouseDrag: false,
		touchDrag: false,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: false,
		responsive: {
			0: {
				dots: false,
			},
			767: {
				dots: false,
			},
			768: {
				dots: true,
			},
		},
	};

	async function get_data() {
		var response = await base.request(base.url_api + "/banner?type="+ (windowSize.width < windowSize.height ? "mobile" : "desktop" )+"&draw=1&is_publish=1")
	
		if(response != null){
			if(response.status == "success"){
				for(let data of response.data){
					data.bgImage = data.url_image
				}
				setSlides(response.data)
			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}

	const owlCarouselOnInitialized = () => {
		setTimeout(function () {
			$(".owl-item.active .owl-slide-animated").addClass("is-transitioned");
		}, 200);
	};
	const owlCarouselOnChanged = (e) => {
		$(".owl-slide-animated").removeClass("is-transitioned");
		const currentOwlItem = $(".owl-item").eq(e.item.index);
		currentOwlItem.find(".owl-slide-animated").addClass("is-transitioned");
	};

	return (
		<>
			<div id="carousel-home">
				<OwlCarousel
					className="owl-carousel owl-theme"
					{...carouselOptions}
					style={{ position: "relative", height: "90vh" }}
					onInitialized={owlCarouselOnInitialized}
					onChanged={owlCarouselOnChanged}
          onResize={() => setTimeout(function(){}, 50)}>
					{slides.map((slide) => (
						<div
							key={slide.id}
							className="owl-slide background-image cover"
							style={{
								width: "100vw",
								height: "100vh",
								backgroundImage: `url(${slide.bgImage})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
							}}>
							<div
								className={"opacity-mask " + (slide.title == '' && slide.subtitle == '' ? 'd-none' : 'd-flex align-items-center')}
								data-opacity-mask="rgba(0, 0, 0, 0.5)">
								<div className="container">
									<div className="row justify-content-center justify-content-md-start">
										<div className="col-lg-6 static">
											<div className="slide-text white">
												<small className="owl-slide-animated owl-slide-title">
													{slide.subtitle}
												</small>
												<h2 className="owl-slide-animated owl-slide-title-2">
													{slide.title}
												</h2>
												{/* <div className="owl-slide-animated owl-slide-title-3">
													<a className="btn_1 outline white mt-3 btn_scrollto" href="#about">
														Read more
													</a>
												</div> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</OwlCarousel>
			</div>
		</>
	);
};

export default HeroSlider;
