import $ from "jquery";
window.$ = window.jquery = $;

import React, { useEffect } from "react";
// import OwlCarousel from "react-owl-carousel";
import Loadable from "@loadable/component";
import { testimonial_bg } from "../../../assets/img";

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

export default function TestimonialsSection() {
  const carouselOptions = {
    items: 1,
    loop: true,
    autoplay: false,
    animateIn: "flipInX",
    margin: 40,
    stagePadding: 30,
    smartSpeed: 300,
    autoHeight: true,
    dots: true,
    responsiveClass: true,
    responsive: {
      600: {
        items: 1,
      },
      1000: {
        items: 1,
        nav: false,
      },
    },
  };

  // useEffect()

  return (
    <div className="parallax_section_1 jarallax" data-jarallax data-speed="0.2">
      <img className="jarallax-img kenburns-2" src={testimonial_bg} alt="" />
      <div
        className="wrapper opacity-mask d-flex align-items-center justify-content-center text-center"
        data-opacity-mask="rgba(0, 0, 0, 0.5)"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="title white">
                <small className="mb-1">Testimonials</small>
                <h2>What Clients Says</h2>
              </div>
              <OwlCarousel
                {...carouselOptions}
                className="carousel_testimonials owl-carousel owl-theme nav-dots-orizontal"
              >
                {Array(3)
                  .fill()
                  .map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="box_overlay">
                          <div className="pic">
                            <figure>
                              <img
                                src="img/testimonial_1.jpg"
                                alt=""
                                className="img-circle"
                              />
                            </figure>
                            <h4>
                              Roberta<small>12 Oct</small>
                            </h4>
                          </div>
                          <div className="comment">
                            "Mea ad postea meliore fuisset. Timeam repudiare id
                            eum, ex paulo dictas elaboraret sed, mel cu unum
                            nostrud."
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
