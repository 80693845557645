import $ from "jquery";
window.$ = window.jquery = $;

import React, { useEffect, useState, } from "react";
import HeroSlider from "./components/HeroSlider";
import AboutSection from "./components/AboutSection";
import TestimonialsSection from "./components/TestimonialsSection";
import RoomsSection from "./components/RoomsSection";
import RunningText from "./components/RunningText";
import MainFacilitiesSection from "./components/MainFacilitiesSection";
import GallerySection from "./components/GallerySection";
import AmenitiesSection from "./components/AmenitiesSection";
import ContactsSection from "./components/ContactsSection";
import "../../../public/js/scrollCue";
import FindRoom from "../components/FindRoom";
import Base from '../utils/base'

const Home = () => {
	var base = new Base()
	const [homeVideo, setHomeVideo] = useState({});
	
  function playVideoOnScroll() {
    // Video Play on scroll
    var $win = $(window);
    var $sectionvideo = $("#section_video video");
    var elementTop, elementBottom, viewportTop, viewportBottom;

    function isScrolledIntoView(elem) {
      elementTop = $(elem).offset().top;
      elementBottom = elementTop + $(elem).outerHeight();
      viewportTop = $win.scrollTop();
      viewportBottom = viewportTop + $win.height();
      return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    if ($sectionvideo.length) {
      var loadVideo;

      $sectionvideo.each(function () {
        $(this).attr("webkit-playsinline", "");
        $(this).attr("playsinline", "");
        $(this).attr("muted", "muted");

        $(this).attr("id", "loadvideo");
        loadVideo = document.getElementById("loadvideo");
        loadVideo.load();
      });

      $win.scroll(function () {
        // video to play when is on viewport

        $sectionvideo.each(function () {
          if (isScrolledIntoView(this) == true) {
            $(this)[0].play();
          } else {
            $(this)[0].pause();
          }
        });
      });
    }
  }

  useEffect(() => {
    scrollCue.init({
      percentage: 0.85,
    });
    scrollCue.update();

    playVideoOnScroll();
		
		get_info()

    return () => {
      scrollCue.enable(false);
    };
  }, []);
	
	async function get_info() {
		var response = await base.request(base.url_api + "/info?type=home_video")
	
		if(response != null){
			if(response.status == "success"){
				setHomeVideo(response.data)
			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}

  return (
    <>
      <HeroSlider />

      <FindRoom />

      <AboutSection />

      {/* <TestimonialsSection /> */}

      {/* <RoomsSection /> */}

      {/* <RunningText /> */}

      <MainFacilitiesSection />

      <div
        className="bg_white"
        data-cue="slideInUp"
        data-delay="300"
        data-show="true"
        style={{
          animationName: "slideInUp",
          animationDuration: "600ms",
          animationTimingFunction: "ease",
          animationDelay: "300ms",
          animationDirection: "normal",
          animationFillMode: "both",
        }}
      >
        <div className="pinned-image pinned-image--medium">
          <div
            className="pinned-image__container"
            id="section_video"
            style={{
              // translate: "none",
              // rotate: "none",
              // scale: "none",
              transform: "scale(1.05, 1.05)",
            }}
          >
            <video
              loop="loop"
              muted="muted"
              id="loadvideo"
              // webkit-playsInline=""
              playsInline=""
            >
              <source src="video/video_1.mp4" type="video/mp4" />
              <source src="video/video_1.webm" type="video/webm" />
              <source src="video/video_1.ogv" type="video/ogg" />
            </video>
            <div
              className="pinned-image__container-overlay"
              style={{ opacity: 1, visibility: "inherit" }}
            ></div>
          </div>
          <div
            className="pinned_over_content"
            style={{ opacity: 1, visibility: "inherit" }}
          >
          <div className="container">
            <div className="title white">
              <small
                data-cue="slideInUp"
                data-delay="200"
                data-show="true"
                style={{
                  animationName: "slideInUp",
                  animationDuration: "600ms",
                  animationTimingFunction: "ease",
                  animationDelay: "200ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {homeVideo.subtitle}
              </small>
              <h2
                data-cue="slideInUp"
                data-delay="300"
                data-show="true"
                style={{
                  animationName: "slideInUp",
                  animationDuration: "600ms",
                  animationTimingFunction: "ease",
                  animationDelay: "300ms",
                  animationDirection: "normal",
                  animationFillMode: "both",
                }}
              >
                {homeVideo.title}
              </h2>
            </div>
          </div>
          </div>
        </div>
      </div>

      {/* <GallerySection /> */}

      {/* <AmenitiesSection /> */}

      {/* <ContactsSection /> */}
    </>
  );
};

export default Home;
