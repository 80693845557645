import { useState } from "react";
// import reactLogo from "./assets/react.svg";
// import viteLogo from "/vite.svg";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { FsLightboxContextProvider } from "./context/FsLightboxContext";
import Layout from "./pages/layout";
import Home from "./pages/home/Home";
import Rooms from "./pages/rooms";
import Gallery from "./pages/gallery";
// import Preloader from "./pages/components/Preloader";

let router = createBrowserRouter(
  [
    {
      path: "/",
      Component: Layout,
      children: [
        {
          index: true,
          // loader: Preloader,
          Component: Home,
          // lazy: Home
        },
        {
          // loader: Preloader,
          path: "/rooms",
          Component: Rooms,
        },
        {
          // loader: Preloader,
          path: "/gallery",
          Component: Gallery,
        },
        // {
        //   path: "todos",
        //   action: todosAction,
        //   loader: todosLoader,
        //   Component: TodosList,
        //   ErrorBoundary: TodosBoundary,
        //   children: [
        //     {
        //       path: ":id",
        //       loader: todoLoader,
        //       Component: Todo,
        //     },
        //   ],
        // },
        // {
        //   path: "deferred",
        //   loader: deferredLoader,
        //   Component: DeferredPage,
        // },
      ],
    },
  ],
  {
    scrollRestoration: "auto",
  }
);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

function App() {
  return (
    <FsLightboxContextProvider>
      <RouterProvider router={router} />
    </FsLightboxContextProvider>
  );
}

export default App;
