import React, {useState, useEffect,} from 'react'
import { fab_instagram, fab_whatsapp } from '../../assets/img'
import Base from '../utils/base'

const FloatingButton = () => {
	var base = new Base()
	const [WALinkData, setWALinkData] = useState({});
	const [instagramData, setInstagramData] = useState({});
	
	useEffect(() => {
		get_setting('wa_link')
		get_setting('instagram')
	}, [])
	
	async function get_setting(key) {
		var response = await base.request(base.url_api + "/setting?key=" + key)
	
		if(response != null){
			if(response.status == "success"){
				if(key == 'wa_link')
					setWALinkData(response.data)
				else if(key == 'instagram')
					setInstagramData(response.data)
			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}
	
  return (
    <div className='fab-container'>
      <a href={WALinkData.value} target='_blank'>
        <img src={fab_whatsapp} width={48} height={48}/>
      </a>
      <a href={instagramData.value} target='_blank'>
        <img src={fab_instagram} width={48} height={48}/>
      </a>
    </div>
  )
}

export default FloatingButton