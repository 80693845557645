/* eslint-disable react/prop-types */
import $ from "jquery";
window.$ = window.jquery = $;

import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { room_1, room_2 } from "../../assets/img";
import "../../../public/js/scrollCue";
import FsLightbox from "fslightbox-react";
import { useFsLightboxContext } from "../../context/FsLightboxContext";
import TopBanner from "../components/TopBanner";
import Base from "../utils/base";

const carouselOptions = {
	center: true,
	items: 1,
	loop: true,
	addClassActive: true,
	margin: 0,
	autoplay: true,
	autoplayTimeout: 4000,
	autoplayHoverPause: true,
	animateOut: "fadeOut",
	responsive: {
		0: {
			dots: true,
		},
		991: {
			dots: true,
		},
	},
};

const rooms = [
	"Park View Deluxe",
	"Park View Twins",
	"Mountain View Deluxe",
	"Mountain View Twins",
];

const RoomsItem = ({ itemCount, imgData, roomsData }) => {
	var base = new Base();

	return (
		<div
			className={`row_list_version_2 ${itemCount % 2 === 1 && "inverted"}`}
			data-cue="slideInUp"
			data-delay="300">
			<div className="row g-0 align-items-center">
				<div className={`col-xl-8 ${itemCount % 2 === 0 ? "" : "order-xl-2"}`}>
					{/* <div className="owl-carousel owl-theme carousel_item_1 kenburns rounded-img">
							<div className="item">
								<a data-fslightbox="rooms_1" data-type="image" href="img/rooms/1.jpg">
									<img src="img/rooms/1.jpg" alt="" />
								</a>
							</div>
							<div className="item">
								<a data-fslightbox="rooms_1" data-type="image" href="img/rooms/opt_1.jpg">
									<img src="img/rooms/opt_1.jpg" alt="" />
								</a>
							</div>
						</div> */}
					{/* <FsLightbox toggler={fsToggler} > */}
					<OwlCarousel
						{...carouselOptions}
						className="owl-carousel owl-theme carousel_item_1 rounded-img owlRooms kenburns-3 owl-loaded owl-drag">
						{roomsData.image.map((image, index) => (
							<div key={index} className="item h-auto">
								<img src={image.url_image} alt="" className="d-block img-carousel-rooms" />
								{/* <a data-fslightbox="rooms" data-type="image" className="h-100">
									</a> */}
							</div>
						))}
					</OwlCarousel>
				</div>
				<div className={`col-xl-4 ${itemCount % 2 === 0 ? "" : "order-xl-1"}`}>
					<div className="box_item_info" data-jarallax-element="-25">
						<small>From Rp. {roomsData.price.toLocaleString(base.locale_string)}/night</small>
						<h2>{roomsData.title}</h2>
						<p>{roomsData.title_description}</p>
						<div className="facilities clearfix">
							<ul>
								{roomsData.facility
									.sort((a, b) => a.description.length - b.description.length)
									.slice(0, 2)
									.map((item, index) => (
										<li key={index}>
											<i className="customicon-double-bed"></i> {item.description}
										</li>
									))}
								{/* <li>
                  <i className="customicon-television"></i> 32 Inc TV
                </li> */}
								{roomsData.facility.length > 2 && (
									<li>
										<div className="btn-group">
											<button
												className="btn btn_1 p-2 dropdown-toggle"
												type="button"
												data-bs-toggle="dropdown">
												{roomsData.facility.length - 2}+
											</button>
											<ul className="dropdown-menu dropdown-menu-end z-index-1">
												{roomsData.facility
													.sort((a, b) => a.description.length - b.description.length)
													.slice(2)
													.map((item, index) => (
														<li key={index} className="dropdown-item m-0 p-0">
														<p className="px-3 py-2 m-0 small" style={{width: "40ch", textWrap: 'balance', color: "#666", lineHeight: 1.2}}>
                            {item.description}
                            </p>
                            </li>
													))}
											</ul>
										</div>
									</li>
								)}
							</ul>
						</div>
						<button className="btn_1 mb-3">Book Now</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const Rooms = () => {
	var base = new Base();
	const roomImage = [room_1, room_2];
	const { fsToggler, setFsToggler } = useFsLightboxContext();
	const [arrRoom, setArrRoom] = useState([]);
	const [roomData, setRoomData] = useState({});

	useEffect(() => {
		get_data();
		get_info();
	}, []);

	useEffect(() => {
		if (arrRoom.length > 0) {
			scrollCue.init({
				percentage: 0.85,
			});
			scrollCue.update();
		}
		return () => {
			if (scrollCue != null) scrollCue.enable(false);
		};
	}, [arrRoom]);

	async function get_info() {
		var response = await base.request(base.url_api + "/info?type=room");

		if (response != null) {
			if (response.status == "success") {
				setRoomData(response.data);
			} else base.show_error(response.message);
		} else console.log("Server Error");
	}

	async function get_data() {
		var response = await base.request(base.url_api + "/room");

		if (response != null) {
			if (response.status == "success") {
				setArrRoom(response.data);
			} else base.show_error(response.message);
		} else console.log("Server Error");
	}

	return (
		<>
			<TopBanner />
			<div className="container margin_120_95" id="rooms">
				<div className="title mb-4">
					<small data-cue="slideInUp">{roomData.subtitle}</small>
					<h2 data-cue="slideInUp" data-delay="200">
						{roomData.title}
					</h2>
				</div>
				{arrRoom.map((item, index) => (
					<RoomsItem key={index} itemCount={index} imgData={roomImage} roomsData={item} />
				))}

				{/* <div className="title text-center mb-5">
          <small data-cue="slideInUp">Luxury B&B Experience</small>
          <h2 data-cue="slideInUp" data-delay="100">
            Main Facilities
          </h2>
        </div>
        <div className="row mt-4">
          <div className="col-xl-3 col-md-6">
            <div className="box_facilities no-border" data-cue="slideInUp">
              <i className="customicon-bath-tub"></i>
              <h3>Large Bath Room</h3>
              <p>
                Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
                laboriosam.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="box_facilities" data-cue="slideInUp">
              <i className="customicon-wifi"></i>
              <h3>High Speed Wifi</h3>
              <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="box_facilities" data-cue="slideInUp">
              <i className="customicon-air-condition"></i>
              <h3>Air Condition</h3>
              <p>
                Similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum
                fuga.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="box_facilities" data-cue="slideInUp">
              <i className="customicon-washing-machine"></i>
              <h3>Wahsing Machine</h3>
              <p>
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                minus.
              </p>
            </div>
          </div>
        </div> */}
			</div>
		</>
	);
};

export default Rooms;
