import React from "react";

const Preloader = () => {
  return (
    <div id="preloader">
      <div data-loader="circle-side"></div>
    </div>
  );
};

export default Preloader;
