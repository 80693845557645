import $ from "jquery";
window.$ = window.jquery = $;

import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import { Outlet, ScrollRestoration, useHref } from "react-router-dom";
import Preloader from "./components/Preloader";
// import "../../public/js/bootstrap.bundle.min.js";
import "../../public/css/vendors.min.css";
// import "../../public/js/scrollCue";
// import "../../public/js/owl.carousel";
// import "../../public/js/slider";
import "../../public/js/easepick.js";
import "../../public/js/daterangepicker.js";
import "../../public/js/datepicker_inline.js";
import "../../public/js/jarallax.js";
import "../../public/js/jarallax-video";

// import "../../public/js/scrolling-nav.js";
// import "../../public/js/fslightbox";
// import "fslightbox";
// import "../../public/js/the";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import moment from "moment";

// import "../../public/js/vegas";

// window.scrollCue = scrollCue;
// import "../../public/js/scrollCue.js";

import "../../public/js/common_scripts";
import FsLightbox from "fslightbox-react";
import { useFsLightboxContext } from "../context/FsLightboxContext";
import { room_1, room_2, testimonial_bg } from "../assets/img/index.js";
import Footer from "./components/Footer.jsx";
import FloatingButton from "./components/FloatingButton.jsx";
// import "../../public/js/common_functions";

const Layout = () => {
  // const [lightBoxToggler, setLightBoxToggler] = useState(false);
  const { fsToggler, setFsToggler, fsData } = useFsLightboxContext();
  const href = useHref();

  useEffect(() => {
    $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
    $("#preloader").addClass("loaded");

    // $(".animate_hero").addClass("is-transitioned");

    $(".opacity-mask").each(function () {
      $(this).css("background-color", $(this).attr("data-opacity-mask"));
    });

    $(".background-image").each(function () {
      $(this).css("background-image", $(this).attr("data-background"));
    });
  }, []);

  useEffect(() => {
    console.log(fsData);
  }, [fsToggler]);

  return (
    <div>
      <Preloader />
      <FsLightbox toggler={fsToggler} sources={fsData} />
      <Navbar />
      <ScrollRestoration />
      <main id="main">
        <Outlet />
      </main>
      <div className="easepick_shadowRoot"></div>
      <FloatingButton />
      <Footer />
    </div>
  );
};

export default Layout;
